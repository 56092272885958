import {
    Button, MenuItem, RadioGroup, TextField, Tooltip
} from '@mui/material';
import { Radio } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import clsx from 'clsx';
import { useContext } from 'react';

import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, UnderwritingQuestionCardProps } from '../underwriting-common';

import styles from './SingleSelectQuestionCard.module.scss';


export function SingleSelectQuestionCard({ question }: UnderwritingQuestionCardProps) {
    const { setReadyToSubmit, updateUnderwritingQuestion } = useContext(UnderWritingStepContext);

    function updateValue(newValue: string) {
        updateUnderwritingQuestion({
            ...question,
            answer: newValue
        });

        const answeredChoice = question.choices?.find(
            choice => choice.text === newValue
        );

        if (answeredChoice?.action === 'CONTINUE') {
            setReadyToSubmit(false);
        } else if (answeredChoice?.action === 'END') {
            setReadyToSubmit(true);
        }
    }

    return (
        <UnderwritingCardWrapper
            question={question}
            className={clsx(styles.singleSelectQuestion, {
                [styles.inlineSingleSelect]: question.id.includes('.') && question.variant === 'SIMPLE',
                [styles.dropdown]: question.variant === 'DROPDOWN'
            })}
        >
            {/* If the variant is dropdown, the question.text is the textbox label */}
            {question.variant !== 'DROPDOWN' && question.text && (
                <Markdown>
                    {question.text}
                </Markdown>
            )}

            {question.variant === 'SIMPLE' && (
                <div className={styles.underwritingAnswerButtons}>
                    {(question.choices?.map(choice => (
                        <Tooltip
                            key={choice.text}
                            title="" // TODO would be nice to add choice.tooltip here
                            enterDelay={0}
                        >
                            <Button
                                key={choice.text}
                                variant={choice.text === question.answer ? 'contained' : 'text'}
                                disableElevation
                                onClick={() => updateValue(choice.text)}
                            >
                                {choice.text}
                            </Button>
                        </Tooltip>
                    )))}
                </div>
            )}

            {question.variant === 'RADIOBUTTON' && (
                <RadioGroup
                    onChange={event => updateValue(event.target.value)}
                    className={styles.radios}
                    value={question.answer}
                >
                    {question.choices?.map(choice => (
                        <Radio
                            key={choice.text}
                            name={question.id}
                            value={choice.text}
                            label={(
                                <Markdown>
                                    {choice.text}
                                </Markdown>
                            )}
                        />
                    ))}
                </RadioGroup>
            )}

            {question.variant === 'DROPDOWN' && (
                <TextField
                    select
                    label={question.text}
                    value={question.answer}
                    onChange={event => updateValue(event.target.value)}
                >
                    {question.choices?.map(choice => (
                        <MenuItem
                            key={choice.text}
                            value={choice.text}
                        >
                            {choice.text}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </UnderwritingCardWrapper>
    );
}
