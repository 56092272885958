import api, {
    UnderwritingCategory, UnderwritingQuestion, UnderwritingQuestionType, UnderwritingStepDetail
} from '@api';
import { Button, Typography } from '@mui/material';
import {
    Loader, replaceItemById, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { replaceItemByIndex, useGetCurrentAccount } from '@utils';
import {
    Dispatch, SetStateAction, createContext, useCallback, useContext, useState
} from 'react';

import { UnderwritingCategoriesContext } from './UnderwritingCategories';
import styles from './UnderwritingStepSection.module.scss';
import { getVisibleQuestionIds, questionTypeToComponentMap } from './underwriting-common';


export interface UnderWritingStepContextValue {
    underwritingStep: UnderwritingStepDetail | undefined;
    setUnderwritingStep: Dispatch<SetStateAction<UnderwritingStepDetail | undefined>>;
    questionIdsToDisplay: string[];
    setQuestionIdsToDisplay: Dispatch<SetStateAction<string[]>>;
    setReadyToSubmit: Dispatch<SetStateAction<boolean>>;
    /**
     * Updates the underwriting question with the new value.
     * Handles determining whether the update should be done to a main question or a nested question in a group.
     * Also updates questionIdsToDisplay based on the updated question.
     */
    updateUnderwritingQuestion:(updatedQuestion: UnderwritingQuestion, updateQuestionsToDisplay?: boolean) => void;
}

export const UnderWritingStepContext = createContext<UnderWritingStepContextValue>({
    underwritingStep: undefined,
    setUnderwritingStep: () => {},
    questionIdsToDisplay: [],
    setQuestionIdsToDisplay: () => {},
    setReadyToSubmit: () => {},
    updateUnderwritingQuestion: () => {}
});

export function UnderwritingStepSection(
    {
        underwritingStepId,
        setUnderwritingStepId,
        underwritingCategory
    }: {
            underwritingStepId: string;
            setUnderwritingStepId: Dispatch<SetStateAction<string | undefined>>;
            underwritingCategory: UnderwritingCategory;
        }
) {
    const [ loading, setLoading ] = useState(true);
    const [ underwritingStep, setUnderwritingStep ] = useState<UnderwritingStepDetail>();
    const { setUnderwritingCategories } = useContext(UnderwritingCategoriesContext);
    const [ questionIdsToDisplay, setQuestionIdsToDisplay ] = useState<string[]>([]);
    const [ readyToSubmit, setReadyToSubmit ] = useState(false);

    const pageMessage = usePageMessage();

    const { id: clientId } = useGetCurrentAccount();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            const underwritingStep = await api.underwriting.getUnderwritingStepDetail(
                clientId, underwritingCategory.id, underwritingStepId
            );
            setUnderwritingStep(underwritingStep);
            setQuestionIdsToDisplay(getVisibleQuestionIds(underwritingStep));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching underwriting step details', error);
        }

        setLoading(false);
    }, [
        clientId, underwritingCategory.id, underwritingStepId, pageMessage
    ]));

    /**
     * Determines whether question is a sub-question or a top-level question.
     * If the question is a sub-question, it will update the question within the questions property of the group.
     * If the question is a top-level question, it will update the question directly.
     *
     * Also updates questionIdsToDisplay based on the updated question.
     */
    function updateUnderwritingQuestion(
        updatedQuestion: UnderwritingQuestion, updateQuestionsToDisplay: boolean = true
    ) {
        let updatedStep: UnderwritingStepDetail;

        const isForEachSubQuestion = updatedQuestion.id.split('.').length === 3;
        // if it's a foreach sub-question, update the arrayData on the parent question
        if (isForEachSubQuestion) {
            const [
                parentQuestionId, arrayItemIndexString, subQuestionId
            ] = updatedQuestion.id.split('.');
            const parentQuestion = underwritingStep!.questions.find(
                q => q.id === parentQuestionId
            );
            const arrayData = parentQuestion?.arrayData || [];
            const arrayItemIndex = parseInt(arrayItemIndexString);

            const isAnswerInArrayData = arrayData[arrayItemIndex].answers?.find(
                (a: Record<string, any>) => a.id === subQuestionId
            );

            const updatedArrayData = replaceItemByIndex(arrayData, {
                ...arrayData[arrayItemIndex],
                // if this question's answer is already in the array data, update the value, otherwise add it
                answers: isAnswerInArrayData ? replaceItemById(
                    arrayData[arrayItemIndex].answers,
                    {
                        id: subQuestionId,
                        value: updatedQuestion.answer
                    }
                ) : [
                    ...arrayData[arrayItemIndex].answers || [],
                    {
                        id: subQuestionId,
                        value: updatedQuestion.answer
                    }
                ]
            }, arrayItemIndex);

            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, {
                    ...parentQuestion!,
                    arrayData: updatedArrayData
                })
            };

            updateQuestionsToDisplay && setQuestionIdsToDisplay(getVisibleQuestionIds(updatedStep));
            setUnderwritingStep(updatedStep);
            return;
        }

        // check if it's a question nested in a group
        const [ groupId ] = updatedQuestion.id.split('.');
        const groupQuestion = underwritingStep!.questions.find(
            q => q.id === groupId && q.type === UnderwritingQuestionType.GROUP
        );
        if (groupQuestion) {
            // if groupQuestion is found, we need to update the question within the group
            const updatedGroup = {
                ...groupQuestion!,
                questions: replaceItemById(groupQuestion!.questions!, updatedQuestion)
            };
            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, updatedGroup)
            };
        } else {
            // if no group is found, update the question directly
            updatedStep = {
                ...underwritingStep!,
                questions: replaceItemById(underwritingStep!.questions, updatedQuestion)
            };
        }

        updateQuestionsToDisplay && setQuestionIdsToDisplay(getVisibleQuestionIds(updatedStep));
        setUnderwritingStep(updatedStep);
    }

    return loading ? <Loader loading /> : (
        <UnderWritingStepContext.Provider value={{
            underwritingStep,
            setUnderwritingStep,
            questionIdsToDisplay,
            setQuestionIdsToDisplay,
            setReadyToSubmit,
            updateUnderwritingQuestion
        }}
        >
            <div className={styles.underwritingStepSection}>
                <div className={styles.underwritingStepSectionTitle}>
                    <div />

                    <Typography variant="h6">
                        {underwritingStep?.name}
                    </Typography>

                    {/* TODO some of the advanced question types will need an add button */}
                    {/* <div className={styles.addButton}>
                        {addButton}
                    </div> */}
                </div>

                {underwritingStep?.questions?.map(question => {
                    const UnderwritingQuestionCard = questionTypeToComponentMap[question.type];
                    return questionIdsToDisplay.includes(question.id)
                        ? (
                            <UnderwritingQuestionCard
                                question={question}
                                key={question.id}
                            />
                        )
                        : null;
                })}

                <Button
                    variant="contained"
                    color="primary"
                    disabled={!readyToSubmit}
                    onClick={async () => {
                        try {
                            const updatedStep = await api.underwriting.updateUnderwritingStep(
                                clientId, underwritingCategory.id, {
                                    ...underwritingStep!,
                                    isSubmitted: true
                                }
                            );

                            setUnderwritingCategories((categories) => replaceItemById(categories, {
                                ...underwritingCategory,
                                steps: replaceItemById(
                                    underwritingCategory.steps,
                                    updatedStep
                                )
                            }));
                        } catch (error) {
                            pageMessage.handleApiError('An error occurred while submitting underwriting step', error);
                        }

                        // go to next step
                        setUnderwritingStepId((stepId) => {
                            const nextStepIndex = underwritingCategory.steps.findIndex(step => step.id === stepId) + 1;
                            return underwritingCategory.steps[nextStepIndex]?.id || '';
                        });
                    }}
                >
                    Submit
                </Button>
            </div>
        </UnderWritingStepContext.Provider>
    );
}
