import {
    CommitmentType, LockPeriod, PricingResultProduct, RegistrationType
} from '@api';
import { AddTask, Check, Lock } from '@mui/icons-material';
import {
    CircularProgress, Popover, Tooltip, Typography
} from '@mui/material';
import {
    CardTable, IconButton, useConfirm
} from '@tsp-ui/core';
import { tooltipTitle } from '@utils';

import styles from './PricingResultProductPopover.module.scss';


interface NoteRatePopoverProps {
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
    noteRatePricingResults: PricingResultProduct[];
    description: string;
    currentNoteRate: number | undefined;
    isExpired: boolean;
    canRegister: boolean;
    isLockDeskOpen: boolean | undefined;
    onRegister: (productId: string, registrationType: RegistrationType, noteRate?: number) => Promise<void>;
    commitmentType: CommitmentType;
    lockPeriod: LockPeriod;
}

export default function PricingResultProductPopover({
    open,
    anchorEl,
    onClose,
    noteRatePricingResults,
    description,
    currentNoteRate,
    isExpired,
    canRegister,
    isLockDeskOpen,
    onRegister,
    commitmentType,
    lockPeriod
}: NoteRatePopoverProps) {
    const confirm = useConfirm();

    const filteredResults = noteRatePricingResults?.filter(
        result => result.commitmentType === commitmentType && result.lockPeriod === lockPeriod
    ) || [];
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <div className={styles.popoverRoot}>
                {filteredResults.length === 0
                    ?  (
                        <div className={styles.popoverLoading}>
                            <CircularProgress
                                size={80}
                                color="primary"
                            />
                        </div>
                    )
                    : (
                        <>
                            <Typography
                                variant="h6"
                                className={styles.productName}
                            >
                                {description}
                            </Typography>

                            <CardTable
                                headers={noteRateTableHeaders}
                            >
                                {filteredResults.map((result) => (
                                    <tr key={result.noteRate}>
                                        <td>
                                            <span>{result.noteRate?.toFixed(3)}%</span>

                                            {result.noteRate === currentNoteRate && (
                                                <Tooltip title="Current note rate">
                                                    <Check
                                                        fontSize="small"
                                                        color="secondary"
                                                        className={styles.check}
                                                    />
                                                </Tooltip>
                                            )}
                                        </td>

                                        <td>{result.investor}</td>

                                        <td>{result.margin?.toFixed(3)}</td>

                                        <td>{result.basePrice?.toFixed(3)}</td>

                                        <td>{result.llpas?.toFixed(3)}</td>

                                        <td>{result.finalPrice?.toFixed(3)}</td>

                                        <td>
                                            <IconButton
                                                tooltip={tooltipTitle({
                                                    [expiredMessage('lock')]: isExpired,
                                                    [noPermissionMessage('lock')]: !canRegister,
                                                    'Cannot lock loan while the lock desk is closed': !isLockDeskOpen,
                                                    'Lock loan': canRegister
                                                })}
                                                size="small"
                                                disabled={!isLockDeskOpen || isExpired || !canRegister}
                                                onClick={async () => {
                                                    const isRateEqual = currentNoteRate === result.noteRate;

                                                    if (isRateEqual || (!isRateEqual && await confirm(
                                                        `Locking this product will change the note rate on the loan from ${currentNoteRate?.toFixed(3)}% to ${result.noteRate?.toFixed(3)}%. Are you sure you want to continue?`
                                                    ))) {
                                                        onRegister(
                                                            result.productId, RegistrationType.LOCK, result.noteRate
                                                        );
                                                    }
                                                }}
                                            >
                                                <Lock
                                                    color="secondary"
                                                    fontSize="small"
                                                />
                                            </IconButton>

                                            <IconButton
                                                tooltip={tooltipTitle({
                                                    [expiredMessage('float')]: isExpired,
                                                    [noPermissionMessage('float')]: !canRegister,
                                                    'Float loan': canRegister
                                                })}
                                                size="small"
                                                disabled={isExpired || !canRegister}
                                                onClick={async () => {
                                                    const isRateEqual = currentNoteRate === result.noteRate;

                                                    if (isRateEqual || (!isRateEqual && await confirm(
                                                        `Floating this product will change the note rate on the loan from ${currentNoteRate?.toFixed(3)}% to ${result.noteRate?.toFixed(3)}%. Are you sure you want to continue?`
                                                    ))) {
                                                        onRegister(
                                                            result.productId, RegistrationType.FLOAT, result.noteRate
                                                        );
                                                    }
                                                }}
                                            >
                                                <AddTask
                                                    color={isExpired ? 'disabled' : 'secondary'}
                                                    fontSize="small"
                                                />
                                            </IconButton>
                                        </td>
                                    </tr>
                                ))}
                            </CardTable>
                        </>
                    )}
            </div>
        </Popover>
    );
}

const expiredMessage = (action: string) => `Pricing expired. Reprice the loan to ${action}`;
const noPermissionMessage = (action: string) => `You do not have permissions to ${action} loans`;
const noteRateTableHeaders = [
    'Note Rate', 'Investor', 'Margin', 'Base Price', 'LLPAs', 'Final Price', ''
];
