import { RestHandler } from 'msw';

import { mocks as authMocks } from './auth/auth-mocks';
import { mocks as availabilityMocks } from './availability/availability-mocks';
import { mocks as bulkCommitmentMocks } from './bulk-commitments/bulk-commitment-mocks';
import { mocks as clientMocks } from './client/client-mocks';
import { mocks as customerAlertConfigMocks } from './client/customerAlertConfig/customerAlertConfig-mocks';
import { mocks as clientInvestorMocks } from './client/investor/clientInvestor-mocks';
import { mocks as losConfigMocks } from './client/los-config/los-config-mocks';
import { mocks as conditionConfigMocks } from './condition-config/condition-config-mocks';
import { mocks as configurableValuesMocks } from './configurable-values/configurable-values-mocks';
import { mocks as customerMocks } from './customer/customer-mocks';
import { mocks as documentMocks } from './document/document-mocks';
import { mocks as documentTypeMocks } from './document-type/document-type-mocks';
import { mocks as dueDiligenceMocks } from './due-diligence-config/due-diligence-mocks';
import { mocks as eligibilityVersionMocks } from './eligibility-version/eligibility-version-mocks';
import { mocks as overlayMocks } from './exclusion-overlay/exclusion-overlay-mocks';
import { mocks as fieldConfigMocks } from './field-config/field-config-mocks';
import { mocks as investorMocks } from './investor/investor-mocks';
import { mocks as llpaMocks } from './llpa/llpa-mocks';
import { mocks as loanMocks } from './loan/loan-mocks';
import { mocks as loanStatusMocks } from './loan-status-config/loan-status-config-mocks';
import { mocks as notificationMocks } from './notification/notification-mocks';
import { mocks as pricingMocks } from './pricing/pricing-mocks';
import { mocks as productsMocks } from './products/products-mocks';
import { mocks as purchaseAdviceMocks } from './purchase-advice/purchaseAdvice-mocks';
import { mocks as referenceGuideMocks } from './reference-guides/referenceGuides-mocks';
import { mocks as notificationsMocks } from './role/notifications/notifications-mocks';
import { mocks as roleMocks } from './role/role-mocks';
import { mocks as underwritingMocks } from './underwriting/underwriting-mocks';
import { mocks as userMocks } from './user/user-mocks';


const isDemo = window.origin.includes('demo');

function maybeIncludeMocks(mocks: RestHandler[], mockForDemo?: boolean): RestHandler[] {
    return (process.env.NODE_ENV === 'development' || (isDemo && mockForDemo))
        ? mocks : [];
}

export const mocks = [
    ...maybeIncludeMocks(authMocks),
    ...maybeIncludeMocks(availabilityMocks),
    ...maybeIncludeMocks(bulkCommitmentMocks),
    ...maybeIncludeMocks(dueDiligenceMocks),
    ...maybeIncludeMocks(documentMocks, true),
    ...maybeIncludeMocks(documentTypeMocks),
    ...maybeIncludeMocks(eligibilityVersionMocks),
    ...maybeIncludeMocks(investorMocks),
    ...maybeIncludeMocks(userMocks),
    ...maybeIncludeMocks(roleMocks),
    ...maybeIncludeMocks(notificationMocks),
    ...maybeIncludeMocks(purchaseAdviceMocks),
    ...maybeIncludeMocks(referenceGuideMocks),
    ...maybeIncludeMocks(notificationsMocks, true),
    ...maybeIncludeMocks(clientMocks),
    ...maybeIncludeMocks(clientInvestorMocks),
    ...maybeIncludeMocks(customerMocks),
    ...maybeIncludeMocks(customerAlertConfigMocks),
    ...maybeIncludeMocks(loanMocks, true),
    ...maybeIncludeMocks(loanStatusMocks),
    ...maybeIncludeMocks(overlayMocks),
    ...maybeIncludeMocks(pricingMocks, true),
    ...maybeIncludeMocks(productsMocks),
    ...maybeIncludeMocks(llpaMocks),
    ...maybeIncludeMocks(conditionConfigMocks),
    ...maybeIncludeMocks(configurableValuesMocks),
    ...maybeIncludeMocks(losConfigMocks),
    ...maybeIncludeMocks(underwritingMocks),
    ...maybeIncludeMocks(fieldConfigMocks)
];
