import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import styles from './Markdown.module.scss';


interface MarkdownProps {
    children?: string;
    valuesToInject?: Record<string, any>;
}

export function Markdown({ children, valuesToInject }: MarkdownProps) {
    const text = valuesToInject ? injectValues(children, valuesToInject) : children;

    return text ? (
        <ReactMarkdown
            rehypePlugins={[ rehypeRaw ]}
            className={styles.markdown}
        >
            {text}
        </ReactMarkdown>
    ) : null;
}

/**
 * Replaces placeholders in the template string with corresponding values from the provided object.
 */
function injectValues(template: string | undefined, values: Record<string, any>): string | undefined {
    const placeholderRegex = /\{\{\s*(\w+)\.(\w+)\s*\}\}/g; // Regular expression to match placeholders in the format {{ obj.key }}

    return template?.replace(
        placeholderRegex,
        (_, obj, key) => ( // Callback to replace each placeholder with the corresponding value from the values object
            values[obj] && values[obj][key] !== undefined
                ? values[obj][key]
                : ''
        )
    );
}
