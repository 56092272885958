import { UnderwritingField, UnderwritingFieldType } from '@api';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, replaceItemByKey } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import { ChangeEvent, useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import { UnderWritingStepContext } from '../UnderwritingStepSection';
import {
    UnderwritingCardWrapper, UnderwritingQuestionCardProps, getVisibleQuestionIds, isUnderwritingFormComplete
} from '../underwriting-common';

import styles from './FormQuestionCard.module.scss';


export function FormQuestionCard({ question }: UnderwritingQuestionCardProps) {
    const {
        underwritingStep, setQuestionIdsToDisplay, setReadyToSubmit, updateUnderwritingQuestion
    } = useContext(UnderWritingStepContext);

    const formIsCompleted = isUnderwritingFormComplete(question);

    useEffect(() => {
        if (question.next?.action === 'END') {
            if (formIsCompleted) {
                setReadyToSubmit(true);
            } else {
                setReadyToSubmit(false);
            }
        }
    }, [
        formIsCompleted, question, setReadyToSubmit
    ]);

    return (
        <UnderwritingCardWrapper
            question={question}
            className={styles.formQuestion}
        >
            <Markdown>
                {question.text}
            </Markdown>

            <UnderwritingQuestionForm
                question={question}
                getValue={field => field.value}
                getOnChange={field => (newValue) => {
                    updateUnderwritingQuestion({
                        ...question,
                        fields: replaceItemByKey(question.fields!, {
                            ...field,
                            value: newValue
                        }, 'dataSourceId')
                    }, false);
                }}
            />

            {/* if question is last in the step, use ready to submit.
            if question has a next action, use this next button instead */}
            {question.next?.action === 'CONTINUE' && (
                <Button
                    onClick={() => {
                        setQuestionIdsToDisplay(getVisibleQuestionIds(underwritingStep!));
                    }}
                    disabled={!formIsCompleted}
                >
                    {question.next.text}
                </Button>
            )}
        </UnderwritingCardWrapper>
    );
}

export function UnderwritingQuestionForm({ question, getValue, getOnChange }: {
    question: UnderwritingQuestionCardProps['question'];
    getValue: (field: UnderwritingField) => string | undefined | Date | null;
    getOnChange: (field: UnderwritingField) => (newValue: string | Date | undefined | null) => void;
}) {
    return (
        <div className={styles.formQuestionFields}>
            {question.fields?.map((field) => {
                const fieldValue = getValue(field);
                const onChange = getOnChange(field);
                const fieldOnChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

                const commonProps = {
                    key: field.dataSourceId,
                    style: {
                        gridColumn: `span ${field.size || 6}`,
                        minWidth: `${field.label.length * 9}px` // Ensure the field grows to fit the label
                    },
                    helperText: field.helperText,
                    label: field.label,
                    value: fieldValue as string,
                    disabled: field.isDisabled,
                    onChange: fieldOnChange
                };

                return (
                    <>
                        {field.type === TEXT && (
                            <TextField {...commonProps} />
                        )}

                        {field.type === NUMBER && (
                            <TextField
                                {...commonProps}
                                type="number"
                                className={styles.hideSpinners}
                            />
                        )}

                        {field.type === DATE && (
                            <DatePicker
                                value={fieldValue || null}
                                onChange={onChange}
                                disabled={field.isDisabled}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        {...commonProps}
                                        onChange={params.onChange}
                                    />
                                )}
                            />
                        )}

                        {field.type === CURRENCY && (
                            <NumericFormat
                                thousandsGroupStyle="thousand"
                                prefix="$"
                                decimalScale={2}
                                decimalSeparator="."
                                displayType="input"
                                thousandSeparator
                                allowNegative
                                customInput={TextField}
                                {...commonProps}
                            />
                        )}

                        {field.type === PERCENTAGE && (
                            <NumericFormat
                                suffix="%"
                                customInput={TextField}
                                {...commonProps}
                            />
                        )}
                    </>
                );
            })}
        </div>
    );
}

const {
    TEXT, NUMBER, DATE, CURRENCY, PERCENTAGE
} = UnderwritingFieldType;
