import { UnderwritingQuestion } from '@api';
import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { DialogContent, Paper } from '@mui/material';
import {
    Button, Dialog, DialogActions, IconButton
} from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import { useContext, useEffect, useState } from 'react';


import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingQuestionCardProps, createForEachQuestionWithIndex, questionTypeToComponentMap } from '../underwriting-common';

import styles from './ForeachQuestionCard.module.scss';
import { UnderwritingQuestionForm } from './FormQuestionCard';


export function ForeachQuestionCard({ question }: UnderwritingQuestionCardProps) {
    const { questionIdsToDisplay, updateUnderwritingQuestion, setReadyToSubmit } = useContext(UnderWritingStepContext);
    const [ addDialogOpen, setAddDialogOpen ] = useState(false);
    const [ indexToEdit, setIndexToEdit ] = useState<number>();

    // if there are no items in the arrayData, set readyToSubmit to false
    useEffect(() => {
        if (!question.arrayData?.length) {
            setReadyToSubmit(false);
        }
    }, [ question.arrayData, setReadyToSubmit ]);

    return (
        <>
            <Button onClick={() => setAddDialogOpen(addDialogOpen => !addDialogOpen)}>
                {question.addButtonText}
            </Button>

            {/* For each item in the arrayData, render a card with all of the subQuestions */}
            {question.arrayData?.map((item, index) => {
                const itemQuestions = question.questions?.map(
                    subQuestionConfig => createForEachQuestionWithIndex(subQuestionConfig, question, index)
                ) || [];

                return (
                    <Paper
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${question.id}.${index}+${JSON.stringify(item)}`}
                        variant="outlined"
                        className={styles.forEachQuestionCard}
                    >
                        <div className={styles.header}>
                            <Markdown valuesToInject={{ [question.arrayOptions!.iterationName]: item }}>
                                {question.text}
                            </Markdown>

                            <div className={styles.iconButtons}>
                                <IconButton
                                    size="small"
                                    tooltip="Delete"
                                    onClick={() => {
                                        updateUnderwritingQuestion({
                                            ...question,
                                            arrayData: question.arrayData?.filter((_, i) => i !== index)
                                        });
                                    }}
                                >
                                    <RemoveCircleOutline color="error" />
                                </IconButton>

                                <IconButton
                                    size="small"
                                    tooltip="Edit"
                                    onClick={() => {
                                        setIndexToEdit(index);
                                        setAddDialogOpen(true);
                                    }}
                                >
                                    <Edit color="secondary" />
                                </IconButton>
                            </div>
                        </div>

                        {itemQuestions.map(subQuestion => {
                            const UnderwritingQuestionCard = questionTypeToComponentMap[subQuestion.type];
                            return questionIdsToDisplay.includes(subQuestion.id)
                                ? (
                                    <UnderwritingQuestionCard
                                        key={subQuestion.id}
                                        question={subQuestion}
                                    />
                                )
                                : null;
                        })}
                    </Paper>
                );
            })}

            {addDialogOpen && (
                <ForEachItemDialog
                    open
                    setOpen={setAddDialogOpen}
                    question={question}
                    indexToEdit={indexToEdit}
                    setIndexToEdit={setIndexToEdit}
                />
            )}
        </>
    );
}

interface ForEachItemDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    question: UnderwritingQuestion;
    indexToEdit: number | undefined;
    setIndexToEdit: (index?: number) => void;
}

function ForEachItemDialog({
    open, setOpen, question, indexToEdit, setIndexToEdit
}: ForEachItemDialogProps) {
    const { updateUnderwritingQuestion } = useContext(UnderWritingStepContext);
    const isEdit = typeof indexToEdit === 'number';
    const itemToEdit = { ...(isEdit ? question.arrayData?.[indexToEdit] : undefined) };

    const [ newItem, setNewItem ] = useState(itemToEdit);

    function onClose() {
        setIndexToEdit();
        setOpen(false);
        setNewItem({});
    }

    // Return true if all required fields in question.fields have corresponding values
    const containsAllRequiredItems = question.fields?.every(
        // if isRequired is undefined, it is assumed to be required
        field => (!(field.isRequired === false) ? newItem[field.dataSourceId] : true)
    );

    return (
        <Dialog
            title={question.addButtonText}
            open={open}
            onClose={onClose}
        >
            <DialogContent className={styles.forEachQuestionDialog}>
                <UnderwritingQuestionForm
                    question={question}
                    getValue={field => newItem[field.dataSourceId]}
                    getOnChange={field => (newValue) => {
                        setNewItem({
                            ...newItem,
                            [field.dataSourceId]: newValue
                        });
                    }}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose}>
                    Cancel
                </Button>

                <Button
                    onClick={() => {
                        if (isEdit) { // If it's an edit, update the item at the index
                            updateUnderwritingQuestion({
                                ...question,
                                arrayData: question.arrayData?.map((item, i) => (i === indexToEdit ? newItem : item))
                            });
                        } else { // If it's an add, add the item to the arrayData
                            updateUnderwritingQuestion({
                                ...question,
                                arrayData: [
                                    ...question.arrayData || [],
                                    newItem
                                ]
                            });
                        }

                        onClose();
                    }}
                    variant="contained"
                    disabled={!containsAllRequiredItems}
                    tooltip={containsAllRequiredItems ? undefined : 'Please fill out all required fields'}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
