import { UnderwritingIconType, UnderwritingQuestion } from '@api';
import {
    CheckCircleOutline, ErrorOutline, InfoOutlined, WarningOutlined
} from '@mui/icons-material';
import { Button, IconTypography, replaceItemById } from '@tsp-ui/core';
import { Markdown } from '@views/components/Markdown';
import { ReactNode, useContext, useEffect } from 'react';

import { UnderWritingStepContext } from '../UnderwritingStepSection';
import { UnderwritingCardWrapper, getVisibleQuestionIds } from '../underwriting-common';

import styles from './InfoQuestionCard.module.scss';


export function InfoQuestionCard({ question }: { question: UnderwritingQuestion }) {
    const {
        setQuestionIdsToDisplay, setReadyToSubmit, setUnderwritingStep, underwritingStep
    } = useContext(UnderWritingStepContext);

    useEffect(() => { // this is for the default rendering of the question, not when a user clicks next
        // might be able to remove this effect
        if (question.next?.action === 'END') {
            setReadyToSubmit(true);
        }
    }, [ question, setReadyToSubmit ]);

    return (
        <UnderwritingCardWrapper
            question={question}
            className={styles.infoQuestionRoot}
        >
            <IconTypography icon={infoQuestionIconMap[question.icon || UnderwritingIconType.INFO]}>
                <Markdown>
                    {question.text}
                </Markdown>
            </IconTypography>


            {question.next?.action === 'CONTINUE' && (
                <Button
                    variant="contained"
                    onClick={() => {
                        const updatedStep = {
                            ...underwritingStep!,
                            questions: replaceItemById(underwritingStep!.questions, {
                                ...question,
                                answer: 'true' // info questions get 'true' when they are answered (aknowledged by the user)
                            })
                        };

                        setUnderwritingStep(updatedStep);
                        setQuestionIdsToDisplay(getVisibleQuestionIds(updatedStep));
                    }}
                >
                    {question.next.text || 'Next'}
                </Button>
            )}
        </UnderwritingCardWrapper>
    );
}

const infoQuestionIconMap: Record<UnderwritingIconType, ReactNode> = {
    INFO: <InfoOutlined color="primary" />,
    WARNING: <WarningOutlined color="warning" />,
    ERROR: <ErrorOutline color="error" />,
    SUCCESS: <CheckCircleOutline color="success" />
};
