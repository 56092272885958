import { Check } from '@mui/icons-material';
import {
    FormControlLabel, Popover, Radio, RadioGroup, Tooltip, Typography
} from '@mui/material';
import React from 'react';

import styles from './NoteRatePopover.module.scss';


interface NoteRatePopoverProps {
    anchorEl: HTMLButtonElement | null;
    onClose: () => void;
    rates: number[] | undefined;
    selectedRate: number | null | undefined;
    onRateSelect: (rate: number) => void;
}

export default function NoteRatePopover({
    anchorEl,
    onClose,
    rates,
    selectedRate,
    onRateSelect
}: NoteRatePopoverProps) {
    const open = Boolean(anchorEl);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        onRateSelect(Number(event.target.value));
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <div className={styles.root}>
                <div className={styles.titleWrapper}>
                    <Typography
                        variant="h6"
                    >
                        Note Rate
                    </Typography>
                </div>

                <RadioGroup
                    name="note-rate"
                    value={selectedRate}
                    onChange={handleChange}
                >
                    {rates?.map((rate) => (
                        <FormControlLabel
                            key={rate}
                            value={rate}
                            control={<Radio />}
                            label={(
                                <div className={styles.rateWrapper}>
                                    <span>{`${rate.toFixed(3)}%`}</span>

                                    {selectedRate === rate && (
                                        <Tooltip
                                            title="Current note rate"
                                        >
                                            <Check
                                                fontSize="medium"
                                                color="secondary"
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                            )}
                        />
                    ))}
                </RadioGroup>
            </div>
        </Popover>
    );
}
