import api, {
    AutomatedUwRecommendation,
    AutomatedUwSystem,
    Borrower,
    CommitmentType,
    DocumentationType,
    LoanDetail, NumUnits,
    SpecialtyProgram,
    amortizationTypeDisplay, automatedUwRecommendationDisplay, automatedUwSystemDisplay,
    commitmentTypeDisplay, documentationTypeDisplay,
    loanPurposeDisplay, loanTypeDisplay, numUnitsDisplay,
    occupancyTypeDisplay, propertyTypeDisplay,
    specialtyProgramDisplay
} from '@api';
import {
    AttachMoney, ExpandMore, HowToReg, LocalAtm, Place, TrendingUp, Verified
} from '@mui/icons-material';
import {
    DialogContent, Divider, IconButton, Tooltip, Typography
} from '@mui/material';
import {
    AddressTypography, FilterTextField, IconTypography, LabelGroup, LabeledValue,
    RoutedDialog, RoutedDialogImplProps
} from '@tsp-ui/core/components';
import {
    formatCurrency, getFullName, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core/utils';
import { useGetCurrentAccount } from '@utils';
import { renderBoolean } from '@views/admin/products/components/ProductDetailsCard';
import React, {
    Fragment, useCallback, useContext, useState
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { LoanDetailContext, LoanStatusButton } from '../LoanDetailPage';
import { PendingLoanDetailContext } from '../PendingLoanDetailPage';

import styles from './LoanDataDialog.module.scss';


interface LocationState {
    isPendingLoan: boolean;
}

export default function LoanDataDialog(props: RoutedDialogImplProps) {
    const { loanDetail: loanDetailFromContext } = useContext(LoanDetailContext || PendingLoanDetailContext);
    const [ loading, setLoading ] = useState(!loanDetailFromContext);
    const [ loanDetail, setLoanDetail ] = useState<LoanDetail | undefined>(loanDetailFromContext);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ debouncedTerm ] = useDebounce(searchTerm, 300);
    const { loanID } = useParams<{ loanID: string }>();
    const location = useLocation();
    const { isPendingLoan } = (location.state as LocationState) || { isPendingLoan: false };

    const { id: clientID, customerId } = useGetCurrentAccount();

    const pageMessage = usePageMessage();

    useAsyncEffect(useCallback(async () => {
        if (!loanDetail) {
            try {
                if (!isPendingLoan) {
                    setLoanDetail(await api.loans.getLoanDetail(clientID, loanID, customerId));
                } else {
                    setLoanDetail(await api.loans.getProspectiveLoanDetail(clientID, loanID, customerId));
                }
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching loan details', error);
            }
            setLoading(false);
        }
    }, [
        loanDetail, isPendingLoan, clientID, loanID, customerId, pageMessage
    ]));

    /**
     * TODO post-demo idea for improvement: use context and some components to
     * handle some of this for us. Create SearchableFieldGroup and SearchableField
     * components where the group knows the fields that are below it in the tree
     * so we don't need to keep logic like this:
     * `(include([ 'borrowers' ])) && render fields` in sync.
     */
    const filterLoanFields = useCallback((loan: LoanDetail) => {
        if (!loan) {
            return [];
        }
        return (Object.keys(loan) as (keyof LoanDetail)[])
            .filter(
                key => String(loan[key]).toLowerCase().includes(debouncedTerm.toLowerCase())
                || (loanFieldLabels[key] || '').toLowerCase().includes(debouncedTerm.toLowerCase())
            );
    }, [ debouncedTerm ]);

    const filteredFields = loanDetail ? filterLoanFields(loanDetail) : [];

    const includeField = (field: (keyof LoanDetail)) => filteredFields.includes(field);

    return (
        <RoutedDialog
            {...props}
            title="Loan data"
            maxWidth={false}
            loading={loading}
        >
            {loanDetail && (
                <>
                    <DialogContent className={styles.headerContent}>
                        <Typography>
                            Loan #
                            <Typography
                                component="span"
                                fontWeight={500}
                            >
                                {loanDetail.loanNumber || loanDetail.customerLoanNumber}
                            </Typography>
                        </Typography>

                        <FilterTextField
                            placeholder="Find a loan field"
                            helperText="Search by field label or value"
                            onChange={(event) => setSearchTerm(event.target.value.toLocaleLowerCase())}
                        />

                        {!isPendingLoan && (
                            <LoanStatusButton
                                status={loanDetail.loanStatus}
                                loanID={loanDetail.id}
                            />
                        )}
                    </DialogContent>

                    <DialogContent className={styles.content}>
                        <div className={styles.column}>
                            <LoanInfo
                                loan={loanDetail}
                                includeField={includeField}
                            />
                        </div>

                        <div className={styles.column}>
                            <PropertyInfo
                                loan={loanDetail}
                                includeField={includeField}
                            />

                            <AdditionalData
                                loan={loanDetail}
                                includeField={includeField}
                            />
                        </div>
                    </DialogContent>
                </>
            )}
        </RoutedDialog>
    );
}

interface LoanInfoProps {
    loan: LoanDetail;
    includeField: (field: (keyof LoanDetail)) => boolean;
}

function LoanInfo({ loan, includeField }: LoanInfoProps) {
    const [ showArmDetails, setShowArmDetails ] = useState(false);

    return (
        <>
            <div>
                <Typography className={styles.header}>Loan Information</Typography>

                <div className={styles.section}>
                    <div className={styles.infoHeader}>
                        {includeField('loanType') && (
                            <Tooltip title={loanFieldLabels['loanType']}>
                                <Typography>{loanTypeDisplay[loan.loanType]}</Typography>
                            </Tooltip>
                        )}

                        <div className={styles.icons}>
                            {includeField('escrowsFlag') && (
                                <Tooltip title={`Taxes & insurance ${loan.escrowsFlag ? '' : 'not'} escrowed`}>
                                    <LocalAtm
                                        color={loan.escrowsFlag ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}

                            {includeField('interestOnlyFlag') && (
                                <Tooltip title={`${loan.interestOnlyFlag ? '' : 'Not'} Interest Only`}>
                                    <TrendingUp
                                        color={loan.interestOnlyFlag ? 'primary' : 'disabled'}
                                        fontSize="small"
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    {includeField('loanAmount') && (
                        <Tooltip title={loanFieldLabels['loanAmount']}>
                            <Typography>{formatCurrency(loan.loanAmount)}</Typography>
                        </Tooltip>
                    )}

                    {includeField('loanTerm') && (
                        <Tooltip title={loanFieldLabels['loanTerm']}>
                            <Typography align="right">
                                {loan.loanTerm ? `${loan.loanTerm} months` : 'Term not specified'}
                            </Typography>
                        </Tooltip>
                    )}

                    {includeField('interestRate') && (
                        <Tooltip title={loanFieldLabels['interestRate']}>
                            <Typography>{loan.interestRate ? `${loan.interestRate.toFixed(3)}%` : '--'}</Typography>
                        </Tooltip>
                    )}

                    {includeField('lockPeriod') && (
                        <Tooltip title={loanFieldLabels['lockPeriod']}>
                            <Typography align="right">
                                {loan.lockPeriod ? `${loan.lockPeriod} days` : 'Lock period not specified'}
                            </Typography>
                        </Tooltip>
                    )}

                    {includeField('amortizationType') && (
                        <Tooltip title={loanFieldLabels['amortizationType']}>
                            <Typography>{loan.amortizationType ? amortizationTypeDisplay[loan.amortizationType] : '--'}</Typography>
                        </Tooltip>
                    )}

                    {loan.amortizationType === 'ADJUSTABLE' && (includeField('armMargin') || includeField('armInitialCap') || includeField('armSubsequentCap') || includeField('armLifeCap')) && (
                        <Tooltip title="Show amortization details">
                            <IconButton
                                size="small"
                                className={styles.showArmButton}
                                onClick={() => setShowArmDetails(!showArmDetails)}
                            >
                                <ExpandMore
                                    color="secondary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>
                    )}

                    {showArmDetails && (
                        <LabelGroup className={styles.armDetails}>
                            {includeField('armMargin') && (
                                <LabeledValue
                                    label={`${loanFieldLabels['armMargin']}:`}
                                    value={loan.armMargin ? `${loan.armMargin}%` : '--'}
                                />
                            )}

                            {includeField('armInitialCap') && (
                                <LabeledValue
                                    label={`${loanFieldLabels['armInitialCap']}:`}
                                    value={loan.armInitialCap ? `${loan.armInitialCap}%` : '--'}
                                />
                            )}

                            {includeField('armSubsequentCap') && (
                                <LabeledValue
                                    label={`${loanFieldLabels['armSubsequentCap']}:`}
                                    value={loan.armSubsequentCap ? `${loan.armSubsequentCap}%` : '--'}
                                />
                            )}

                            {includeField('armLifeCap') && (
                                <LabeledValue
                                    label={`${loanFieldLabels['armLifeCap']}:`}
                                    value={loan.armLifeCap ? `${loan.armLifeCap}%` : '--'}
                                />
                            )}
                        </LabelGroup>
                    )}

                </div>
            </div>

            {includeField('borrowers') && (
                <div>
                    <Typography className={styles.header}>
                        Borrowers
                    </Typography>

                    <div className={styles.borrowers}>
                        {loan.borrowers.map((borrower, index) => (
                            <Fragment
                                key={borrower.id}
                            >
                                <BorrowerCard
                                    borrower={borrower}
                                    loan={loan}
                                />

                                {index < loan.borrowers.length - 1 && (
                                    <Divider className={styles.divider} />
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}

function BorrowerCard({ borrower, loan }: { borrower: Borrower, loan: LoanDetail }) {
    const [ hideSSN, setHideSSN ] = useState(true);
    const [ hideContactInfo, setHideContactInfo ] = useState(true);

    function formatSSN(borrower: Borrower) {
        if (!borrower.ssn) {
            return 'N/A';
        }
        return hideSSN ? `*-${borrower.ssn.slice(-4)}` : borrower.ssn;
    }

    function toggleSSNVisibility() {
        setHideSSN(!hideSSN);
    };

    function toggleContactVisibility() {
        setHideContactInfo(!hideContactInfo);
    };

    return (
        <div>
            <div className={styles.borrowerCardHeader}>
                <Tooltip title={hideContactInfo ? 'Show Contact Info' : 'Hide Contact Info'}>
                    <Typography
                        onClick={toggleContactVisibility}
                        variant="body1"
                        className={styles.ssn}
                    >
                        {getFullName(borrower)}
                    </Typography>
                </Tooltip>

                <div className={styles.icons}>
                    <Tooltip
                        title={`${borrower.primaryWageEarner ? 'P' : 'Not the p'}rimary wage earner`}
                        enterDelay={0}
                    >
                        <AttachMoney
                            color={borrower.primaryWageEarner ? 'primary' : 'disabled'}
                            fontSize="small"
                        />
                    </Tooltip>

                    <Tooltip
                        title={`${loan.firstTimeHomeBuyer ? 'F' : 'Not a f'}irst time home buyer`}
                        enterDelay={0}
                    >
                        <HowToReg
                            color={loan.firstTimeHomeBuyer ? 'primary' : 'disabled'}
                            fontSize="small"
                        />
                    </Tooltip>
                </div>
            </div>

            {!hideContactInfo && borrower.email}

            <div className={styles.borrowerDetails}>
                <LabelGroup>
                    <LabeledValue
                        label="FICO:"
                        value={(
                            <IconTypography
                                compact
                                iconPosition="after"
                                fontWeight={400}
                                variant="inherit"
                                icon={(
                                    <Verified
                                        color="primary"
                                        fontSize="inherit"
                                    />
                                )}
                            >
                                {borrower.fico}
                            </IconTypography>
                        )}
                    />
                </LabelGroup>

                <LabelGroup>
                    <LabeledValue
                        label="SSN:"
                        value={(
                            <Tooltip title={hideSSN ? 'Reveal' : 'Hide'}>
                                <Typography
                                    onClick={toggleSSNVisibility}
                                    variant="body2"
                                    className={styles.ssn}
                                >
                                    {formatSSN(borrower)}
                                </Typography>
                            </Tooltip>
                        )}
                    />
                </LabelGroup>
            </div>
        </div>
    );
}

interface PropertyInfoProps {
    loan: LoanDetail;
    includeField: (field: (keyof LoanDetail)) => boolean;
}

function PropertyInfo({ loan, includeField }: PropertyInfoProps) {
    return (
        <div>
            <Typography className={styles.header}>Property Details</Typography>

            <div className={styles.section}>
                {includeField('propertyType') && (
                    <Tooltip title={loanFieldLabels['propertyType']}>
                        <Typography>
                            {loan.propertyType ? propertyTypeDisplay[loan.propertyType] : 'Property type not specified'}
                        </Typography>
                    </Tooltip>
                )}

                {includeField('units') && (
                    <Tooltip title={loanFieldLabels['units']}>
                        <Typography align="right">
                            {loan.units ? `${numUnitsDisplay[loan.units]} unit${loan.units !== NumUnits.ONE ? 's' : ''}` : 'Number of units not specified'}
                        </Typography>
                    </Tooltip>
                )}

                {includeField('purpose') && (
                    <Tooltip title={loanFieldLabels['purpose']}>
                        <Typography>
                            {loan.purpose ? loanPurposeDisplay[loan.purpose] : 'Loan purpose not specified'}
                        </Typography>
                    </Tooltip>
                )}

                {includeField('occupancy') && (
                    <Tooltip title={loanFieldLabels['occupancy']}>
                        <Typography align="right">
                            {loan.occupancy ? occupancyTypeDisplay[loan.occupancy] : 'Occupancy type not specified'}
                        </Typography>
                    </Tooltip>
                )}

                {includeField('address') && (
                    <>
                        <Divider className={styles.divider} />

                        <Tooltip title={loanFieldLabels['address']}>
                            <div className={styles.propertyAddress}>
                                <Place color="primary" />

                                <AddressTypography address={loan.address} />
                            </div>
                        </Tooltip>

                        <Divider className={styles.divider} />
                    </>
                )}

                <LabelGroup className={styles.justifyStart}>
                    {includeField('appraisedValue') && (
                        <LabeledValue
                            label={`${loanFieldLabels['appraisedValue']}:`}
                            value={formatCurrency(loan.appraisedValue) || '--'}
                            variants={{
                                label: 'body2',
                                value: 'body1'
                            }}
                        />
                    )}
                </LabelGroup>

                <LabelGroup className={styles.justifyEnd}>
                    {includeField('salePrice') && (
                        <LabeledValue
                            label={`${loanFieldLabels['salePrice']}:`}
                            value={formatCurrency(loan.salePrice) || '--'}
                            variants={{
                                label: 'body2',
                                value: 'body1'
                            }}
                        />
                    )}
                </LabelGroup>
            </div>
        </div>
    );
}

interface AdditionalDataProps {
    loan: LoanDetail;
    includeField: (field: (keyof LoanDetail)) => boolean;
}

function AdditionalData({ loan, includeField }: AdditionalDataProps) {
    function getEnumDisplay<T extends string | number>(
        value: unknown,
        enumObject: { [key: string]: T },
        displayObject: { [key in T]: string }
    ): string {
        return (typeof value === 'string' && Object.values(enumObject).includes(value as T))
            ? displayObject[value as T]
            : '--';
    }

    const renderValue = (key: (keyof LoanDetail), value: unknown) => {
        if (typeof value === 'boolean') {
            return renderBoolean(value);
        }
        if (typeof value === 'number' && [
            'cashOutAmount', 'subordinatedBalance', 'mortgageInsCoverage', 'subordinatedLoanAmount', 'totalLoanAmount'
        ].includes(key)) {
            return formatCurrency(value);
        }
        switch (key) {
        case 'specialtyProgram':
            return getEnumDisplay(value, SpecialtyProgram, specialtyProgramDisplay);
        case 'documentationType':
            return getEnumDisplay(value, DocumentationType, documentationTypeDisplay);
        case 'commitmentType':
            return getEnumDisplay(value, CommitmentType, commitmentTypeDisplay);
        case 'automatedUwSystem':
            return getEnumDisplay(value, AutomatedUwSystem, automatedUwSystemDisplay);
        case 'automatedUwRecommendation':
            return getEnumDisplay(value, AutomatedUwRecommendation, automatedUwRecommendationDisplay);
        default:
            return value?.toString() || '--';
        }
    };

    const additionalFields: (keyof LoanDetail)[] = [
        'customerName', 'customerId', 'customerLoanNumber', 'productCode', 'specialtyProgram',
        'documentationType', 'subordinatedBalance', 'cashOutAmount', 'limitedLiabilityCorp',
        'commitmentType', 'commitmentIdentifier', 'mortgageInsFlag', 'mortgageInsCompany',
        'mortgageInsCoverage', 'underwriteFlag', 'automatedUwSystem', 'automatedUwRecommendation'
    ];

    return (
        <div>
            <Typography className={styles.header}>Additional Data</Typography>

            <div className={styles.section}>
                <LabelGroup className={styles.justifyStart}>
                    {additionalFields.map((field) => includeField(field) && (
                        <LabeledValue
                            key={field}
                            label={`${loanFieldLabels[field]}:`}
                            value={renderValue(field, loan[field])}
                            variants={{
                                label: 'body2',
                                value: 'body1'
                            }}
                        />
                    ))}
                </LabelGroup>
            </div>
        </div>
    );
}

const loanFieldLabels: Partial<Record<keyof LoanDetail, string>> = {
    loanNumber: 'Loan number',
    assignee: 'Assignee',
    loanStatus: 'Loan status',
    loanType: 'Loan type',
    loanAmount: 'Loan amount',
    interestRate: 'Interest rate',
    amortizationType: 'Amortization type',
    armMargin: 'Arm margin',
    armInitialCap: 'Arm initial cap',
    armSubsequentCap: 'Arm subsequent cap',
    armLifeCap: 'Arm life cap',
    escrowsFlag: 'Escrows flag',
    interestOnlyFlag: 'Interest only flag',
    loanTerm: 'Loan term',
    lockPeriod: 'Lock period',
    borrowers: 'Borrowers',
    propertyType: 'Property type',
    units: 'Number of units',
    occupancy: 'Occupancy type',
    purpose: 'Purpose',
    address: 'Property address',
    appraisedValue: 'Appraised value',
    salePrice: 'Sale price',
    customerName: 'Customer name',
    customerId: 'Customer ID',
    customerLoanNumber: 'Customer loan number',
    productCode: 'Product code',
    specialtyProgram: 'Specialty program',
    documentationType: 'Documentation type',
    subordinatedBalance: 'Subordinated balance',
    cashOutAmount: 'Cash out amount',
    limitedLiabilityCorp: 'Limited liability corp',
    commitmentType: 'Commitment type',
    commitmentIdentifier: 'Commitment identifier',
    mortgageInsFlag: 'Mortgage ins flag',
    mortgageInsCompany: 'Mortgage ins company',
    mortgageInsCoverage: 'Mortgage ins coverage',
    underwriteFlag: 'Underwrite flag',
    automatedUwSystem: 'AUS',
    automatedUwRecommendation: 'AUR'
};
