import api, { UnderwritingCategory } from '@api';
import { List } from '@mui/material';
import {
    ListItemLink, Loader, getItemById, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core';
import { useGetCurrentAccount } from '@utils';
import clsx from 'clsx';
import {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';

import ConditionsSummary from '../conditions/ConditionsSummary';

import styles from './UnderwritingCategories.module.scss';
import { UnderwritingCategoryDetail } from './UnderwritingCategoryDetail';


export interface UnderwritingCategoriesContextValue {
    underwritingCategories: UnderwritingCategory[];
    setUnderwritingCategories: Dispatch<SetStateAction<UnderwritingCategory[]>>;
}

export const UnderwritingCategoriesContext = createContext<UnderwritingCategoriesContextValue>({
    underwritingCategories: [],
    setUnderwritingCategories: () => {}
});

export function UnderwritingCategories() {
    const { underwritingCategoryId } = useParams<{ loanID: string }>();
    const { id: clientId } = useGetCurrentAccount();
    const [ underwritingCategories, setUnderwritingCategories ] = useState<UnderwritingCategory[]>([]);
    const pageMessage = usePageMessage();
    const [ loading, setLoading ] = useState(true);

    useAsyncEffect(useCallback(async () => {
        try {
            setUnderwritingCategories(await api.underwriting.getUnderwritingCategories(clientId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching underwriting categories', error);
        }

        setLoading(false);
    }, [ clientId, pageMessage ]));

    return (
        <UnderwritingCategoriesContext.Provider value={{
            underwritingCategories,
            setUnderwritingCategories
        }}
        >
            <div className={styles.content}>
                {loading ? <Loader loading /> : (
                    <>
                        <List disablePadding>
                            <ListItemLink
                                to={`${underwritingCategoryId ? './../' : ''}all`}
                                selected={underwritingCategoryId === 'all'}
                                classes={{
                                    selected: clsx(styles.selected, {
                                        [styles.extraWidth]: false
                                    })
                                }}
                            >
                                All Conditions
                            </ListItemLink>


                            {underwritingCategories?.map((category) => (
                                <ListItemLink
                                    to={`${underwritingCategoryId ? './../' : ''}${category.id || ''}`}
                                    selected={
                                        !!underwritingCategoryId && underwritingCategoryId === category.id
                                    }
                                    classes={{
                                        selected: clsx(styles.selected, {
                                            [styles.extraWidth]: true
                                        })
                                    }}
                                >
                                    {category.name}
                                </ListItemLink>
                            ))}
                        </List>


                        {underwritingCategoryId && underwritingCategoryId !== 'all' ? (
                            <UnderwritingCategoryDetail
                                category={getItemById(underwritingCategories, underwritingCategoryId)}
                            />
                        ) : (
                            <ConditionsSummary
                                header="All conditions"
                                className={styles.fullWidth}
                            />
                        )}
                    </>
                )}
            </div>
        </UnderwritingCategoriesContext.Provider>
    );
}
