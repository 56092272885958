import { rest } from 'msw';

import { getMockUrl } from '../../mocks/getMockUrl';

import {
    UnderwritingCategory, UnderwritingFieldType, UnderwritingIconType, UnderwritingQuestionType, UnderwritingStepDetail
} from './underwriting-api';


export const mocks = [
    rest.get(getMockUrl('/client/:clientId/underwriting-category'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(underwritingCategories)
    ))),
    rest.get(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryId/underwriting-step/:underwritingStepId'), (req, res, ctx) => (res(
        ctx.status(200),
        ctx.json(getUnderwritingStepById(req.params.underwritingStepId as string))
    ))),
    rest.put(getMockUrl('/client/:clientId/underwriting-category/:underwritingCategoryId/underwriting-step/:underwritingStepId'), (req, res, ctx) => {
        const underwritingStep = req.body as UnderwritingStepDetail;
        const index = underwritingSteps.findIndex(step => step.id === req.params.underwritingStepId);
        underwritingSteps[index] = underwritingStep;

        return (res(
            ctx.status(200),
            ctx.json(underwritingStep)
        ));
    })
];

export function getUnderwritingStepById(underwritingStepId: string): UnderwritingStepDetail | undefined {
    return underwritingSteps.find(step => step.id === underwritingStepId);
}

const {
    TEXT, NUMBER, DATE, CURRENCY, PERCENTAGE
} = UnderwritingFieldType;

export const underwritingCategories: UnderwritingCategory[] = [
    {
        id: '0',
        name: 'AUS',
        steps: [
            {
                id: '8',
                name: 'AUS'
            }
        ]
    },
    {
        id: '1',
        name: 'Sales Contract',
        steps: [
            {
                id: '13',
                name: 'Sales Contract'
            }
        ]
    },
    {
        id: '2',
        name: 'Credit & Liabilities',
        steps: [
            {
                id: '9',
                name: 'Credit inquiries'
            },
            {
                id: '10',
                name: 'Fraud check'
            },
            {
                id: '11',
                name: 'Mortgage history'
            },
            {
                id: '12',
                name: 'Report details'
            }
        ]
    },
    {
        id: '3',
        name: 'Income & Assets',
        steps: [
            {
                id: '0',
                name: 'Earnest Money'
            },
            {
                id: '1',
                name: 'Gift Funds'
            },
            {
                id: '2',
                name: 'Net Equity'
            }
        ]
    },
    {
        id: '4',
        name: 'Appraisal',
        steps: [
            {
                id: '3',
                name: 'Appraisal'
            },
            {
                id: '4',
                name: 'Appraisal Review'
            },
            {
                id: '5',
                name: 'Appraiser'
            },
            {
                id: '6',
                name: 'Appraiser Details'
            },
            {
                id: '7',
                name: 'Condo info'
            }
        ]
    },
    {
        id: '5',
        name: 'Title & MI',
        steps: [
            {
                id: '14',
                name: 'Living Trust Review'
            },
            {
                id: '15',
                name: 'Mortgage Insurance'
            },
            {
                id: '16',
                name: 'Title Commitment'
            }
        ]
    },
    {
        id: '6',
        name: 'Fraud Report',
        steps: [
            {
                id: '17',
                name: 'Fraud Report'
            },
            {
                id: '18',
                name: 'Parties Review'
            }
        ]
    },
    {
        id: '7',
        name: 'Flood & Hazard',
        steps: [
            {
                id: '19',
                name: 'Flood Insurance'
            },
            {
                id: '20',
                name: 'Hazard Insurance'
            }
        ]
    }
];

// TODO remove sideEffects from the mock data when they are implemented in websocket
const underwritingSteps: UnderwritingStepDetail[] = [
    {
        id: '0',
        name: 'Earnest Money',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is earnest money deposit required in the sales contract?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FORM,
                text: 'Earnest Money',
                fields: [
                    {
                        dataSourceId: 'loan.data.earnest_money',
                        label: 'Earnest money deposit',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money_1',
                        label: 'Earnest money total amount',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money_2',
                        label: 'Earnest money interest',
                        size: 12,
                        isDisabled: false,
                        type: PERCENTAGE
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the deposit cleared the borrower\'s bank account?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
                // Has the deposit cleared the borrower's bank account?
            }
        ]
    }, {
        id: '1',
        name: 'Gift Funds',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are there any gift funds for this transaction?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                answer: 'Yes'
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FOREACH,
                text: '**{{ gift_fund.amount }}** from **{{ gift_fund.name }}**',
                addButtonText: 'Add Gift',
                arrayOptions: {
                    dataSourceId: 'loan.data.gift_funds',
                    iterationName: 'gift_fund',
                    indexName: 'i'
                },
                fields: [
                    {
                        dataSourceId: 'name',
                        label: 'Donor name',
                        size: 12,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'amount',
                        label: 'Amount',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                /**
                 * Array data coming through in this format is an assumption based on the UI.
                 * We can rework this depending on what makes sense for the backend.
                 */
                arrayData: [
                    {
                        name: 'Some Giver',
                        amount: '$5000',
                        answers: [
                            {
                                id: '0',
                                value: 'Yes'
                            },
                            {
                                id: '1',
                                value: 'Borrower'
                            },
                            {
                                id: '2',
                                value: 'No'
                            }
                        ]
                    },
                    {
                        name: 'Better Giver',
                        amount: '$15000',
                        answers: [
                            {
                                id: '0',
                                value: 'Yes'
                            },
                            {
                                id: '1',
                                value: 'Borrower'
                            },
                            {
                                id: '2',
                                value: 'No'
                            }
                        ]
                    }
                ],
                questions: [
                    {
                        id: '1.i.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the gift letter on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.1',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '1.i.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Who is the gift fund recipient?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.2',
                                text: 'Borrower'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '1.i.2',
                                text: 'Closing agent'
                            },
                            {
                                action: 'END',
                                text: 'Gift of equity'
                            }
                        ]
                    },
                    {
                        id: '1.i.2',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are the donor funds sourced?',
                        choices: [
                            {
                                action: 'END',
                                text: 'Yes'
                            },
                            {
                                action: 'END',
                                text: 'No'
                            }
                        ]
                    }
                ],
                /**
                 * NOTE FOR KEN:
                 * We need to have one next action on the foreach that represents what happens after all items in the
                 * foreach have been answered. It doesn't make sense to have 1.1.1 trigger 2, because 1.2.1 might not
                 * be answered yet. We can handle this but using END as the action on the subquestions to represent they
                 * are ready. Once all subquestions in the foreach reach end state, we can trigger the next action on
                 * the parent foreach question
                 */
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Continue'
                }
            },
            {
                id: '2',
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'All items complete',
                next: {
                    action: 'END',
                    text: 'Done'
                }
            }
        ]
    }, {
        id: '2',
        name: 'Net Equity',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the borrower gaining net equity from the sale of real estate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ]
            },
            {
                id: '1',
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.net_equity',
                        label: 'Net equity gained',
                        size: 12,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                }
            }
        ]
    }, {
        id: '3',
        name: 'Appraisal',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the appraisal match the property?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: '',
                        label: 'Street',
                        size: 12,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '',
                        label: 'Street line 2 (optional)',
                        size: 12,
                        isDisabled: false,
                        type: TEXT,
                        isRequired: false
                    },
                    {
                        dataSourceId: '',
                        label: 'City',
                        size: 6,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '',
                        label: 'State',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '',
                        label: 'Zip',
                        size: 3,
                        isDisabled: false,
                        type: NUMBER
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What type of appraisal was performed?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '1004'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '2055'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '1073'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '1075'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '1025'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '1025 Exterior'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: '2090'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What type of property is the appraisal for?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'Single family'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'Condo'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'PUD'
                    },
                    {
                        action: 'END',
                        text: 'Co-op'
                    },
                    {
                        action: 'END',
                        text: 'Manufactured home'
                    }
                ],
                id: '3'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the property attached or detached?',
                choices: [
                    {
                        action: 'END',
                        text: 'Attached'
                    },
                    {
                        action: 'END',
                        text: 'Detached'
                    }
                ],
                id: '4'
            }
        ]
    },
    {
        id: '4',
        name: 'Appraisal Review',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a 1040D for the subject to appraisal?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the report contain interior photos?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the property rural or over 10 acres?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'No'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the appraised value $415,000 supported?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '3'
            }
        ]
    },
    {
        id: '5',
        name: 'Appraiser',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is an appraisal waived for this file?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is an appraisal present on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No',
                        sideEffects: [
                            {
                                type: 'SUSPEND_LOAN'
                            }
                        ]
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: '6',
        name: 'Appraiser Details',
        questions: [
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: '0',
                        label: 'Appraiser name',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '1',
                        label: 'License number',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: '2',
                        label: 'Appraisal company',
                        size: 4,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '0'
            }
        ]
    },
    {
        id: '7',
        name: 'Condo info',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the condo project new?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the entire project contain less than 5 total units?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.ERROR,
                text: 'Full condo review required',
                next: {
                    action: 'CONTINUE',
                    continueTo: '5',
                    text: 'Next'
                },
                id: '2'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.SUCCESS,
                text: 'No condo review required',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.WARNING,
                text: 'Limited condo review required',
                next: {
                    action: 'CONTINUE',
                    continueTo: '6',
                    text: 'Next'
                },
                id: '4'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are the following warranty documents included?\n- Full condo questionnaire\n- HOA Budget\n- HOA Balance sheet',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '5'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Are the following warranty documents included?\n- Limited condo questionnaire',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '6'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the limited review sufficient?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '7'
            }
        ]
    },
    {
        id: '8',
        name: 'AUS Review',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the above information match the AUS results',
                choices: [
                    {
                        action: 'END',
                        text: 'No',
                        sideEffects: [
                            {
                                type: 'GENERATE_CONDITION',
                                text: "AUS results don't match loan information"
                            }
                        ]
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Have conditions been added to address all AUS findings?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: '9',
        name: 'Credit inquiries',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the credit report show other queries in the last 90 days?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a letter of explanation for each of those inquiries?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.TABLE_ENTRY,
                text: 'Inquiries requiring explanation',
                entityName: 'inquiry',
                columns: [
                    {
                        columnName: 'Creditor name',
                        loanFieldId: 'loan.data.inquiries[].creditor_name'
                    },
                    {
                        columnName: 'Date',
                        loanFieldId: 'loan.data.inquiries[].date'
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            }
        ]
    },
    {
        id: '10',
        name: 'Fraud check',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a fraud check?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there a processor certification to satisfy the alert(s)?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            }
        ]
    },
    {
        id: '11',
        name: 'Mortgage history',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is there satisfactory 12 month mortgage payment history for all properties owned?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.TABLE_ENTRY,
                text: 'Properties requiring mortgage history',
                entityName: 'property',
                columns: [
                    {
                        columnName: 'Property address',
                        loanFieldId: 'loan.data.properties[].address'
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does any mortgage have late payments in the last 12 months?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Yes'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.late_payments.30_days',
                        label: '30 days',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.60_days',
                        label: '60 days',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.90_days',
                        label: '90 days',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.late_payments.120_days',
                        label: '120 days',
                        size: 3,
                        isDisabled: false,
                        type: TEXT
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done',
                    sideEffects: [
                        {
                            type: 'GENERATE_CONDITION',
                            text: 'Late payments in the last 12 months'
                        }
                    ]
                },
                id: '3'
            }
        ]
    },
    {
        id: '12',
        name: 'Report details',
        questions: [
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.borrower_name',
                        label: 'Borrower name',
                        size: 4,
                        isDisabled: true,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.report_date',
                        label: 'Report date',
                        size: 4,
                        isDisabled: false,
                        type: DATE
                    },
                    {
                        dataSourceId: 'loan.data.report_number',
                        label: 'Report #',
                        size: 4,
                        isDisabled: true,
                        type: TEXT
                    },
                    {
                        dataSourceId: 'loan.data.transunion',
                        label: 'Transunion',
                        size: 4,
                        isDisabled: true,
                        type: NUMBER
                    },
                    {
                        dataSourceId: 'loan.data.equifax',
                        label: 'Equifax',
                        size: 4,
                        isDisabled: false,
                        type: NUMBER
                    },
                    {
                        dataSourceId: 'loan.data.experian',
                        label: 'Experian',
                        size: 4,
                        isDisabled: false,
                        type: NUMBER
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '0'
            }
        ]
    },
    {
        id: '13',
        name: 'Sales Contract',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the sales contract on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Does the property on the sales contract match the following address from the file?\n<center>{{ loan.data.address }}</center>',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: 'loan.data.purchase_price',
                        label: 'Purchase price',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.seller_concessions',
                        label: 'Seller concessions',
                        size: 6,
                        helperText: 'Maximum concessions: $4,000',
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.earnest_money',
                        label: 'Earnest money deposit',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: 'loan.data.contract_execution_date',
                        label: 'Contract execution date',
                        size: 6,
                        isDisabled: false,
                        type: DATE
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            }
        ]
    },
    {
        id: '14',
        name: 'Living Trust Review',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'RADIOBUTTON',
                text: 'Which of the following best describes the titling of this loan regarding a living trust?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Title is currently vested in the trust and will **remain** in the trust'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Title is currently vested in the trust, but **will be deeded out** of the trust at closing'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Title is **not** currently in a trust but **will be vested** in a trust at closing'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.ERROR,
                text: 'Full living trust review required.',
                next: {
                    action: 'CONTINUE',
                    text: 'Next',
                    continueTo: '3'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.SUCCESS,
                text: 'No living trust review required.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '2'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the full living trust document on file?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '4',
                        text: 'Yes'
                    }
                ],
                id: '3'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the trust approval been obtained from the title company?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'END',
                        text: 'Yes'
                    }
                ],
                id: '4'
            }
        ]
    },
    {
        id: '15',
        name: 'Mortgage Insurance',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is mortgage insurance required?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What is the loan purpose listed on the mortgage insurance certificate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Purchase'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Refinance'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Cash Out Refinance'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'What is the property type listed on the mortgage insurance certificate?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Single Family'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Condo'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'PUD'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Co-op'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '3',
                        text: 'Manufactured Home'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.FORM,
                text: 'Enter the following information from the mortgage insurance certificate:',
                fields: [
                    {
                        dataSourceId: '1',
                        label: 'Loan Amount',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: '2',
                        label: 'LTV',
                        size: 3,
                        isDisabled: false,
                        type: PERCENTAGE
                    },
                    {
                        dataSourceId: '3',
                        label: 'CLTV',
                        size: 3,
                        isDisabled: false,
                        type: PERCENTAGE
                    },
                    {
                        dataSourceId: '4',
                        label: 'Monthly Premium',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    },
                    {
                        dataSourceId: '5',
                        label: 'Coverage Percentage',
                        size: 6,
                        isDisabled: false,
                        type: PERCENTAGE
                    }
                ],
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            }
        ]
    },
    {
        id: '16',
        name: 'Title Commitment',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the title on file?',
                choices: [
                    {
                        action: 'END',
                        text: 'No'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.FORM,
                fields: [
                    {
                        dataSourceId: '1',
                        label: 'Commitment Date',
                        size: 6,
                        helperText: 'Expires {{ loan.data.commitment_expiry_date }}',
                        isDisabled: false,
                        type: DATE
                    },
                    {
                        dataSourceId: '2',
                        label: 'Loan Amount',
                        size: 6,
                        isDisabled: false,
                        type: CURRENCY
                    }
                ],
                next: {
                    action: 'CONTINUE',
                    continueTo: '2',
                    text: 'Next'
                },
                id: '1'
            },
            {
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: "Is the lender's name correct?",
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.1',
                                text: 'Yes'
                            }
                        ],
                        id: '2.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the vesting correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.2',
                                text: 'Yes'
                            }
                        ],
                        id: '2.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title clear of defects?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.3',
                                text: 'Yes'
                            }
                        ],
                        id: '2.2'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is COVID-19 hold harmless language included?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.4',
                                text: 'Yes'
                            }
                        ],
                        id: '2.3'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Are there any delinquent taxes?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.5',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.5',
                                text: 'Yes'
                            }
                        ],
                        id: '2.4'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is a 24 month chain of title shown?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.6',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.6',
                                text: 'Yes'
                            }
                        ],
                        id: '2.5'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title fee simple or leasehold?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.7',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.7',
                                text: 'Yes'
                            }
                        ],
                        id: '2.6'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is an insured closing letter on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.8',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.8',
                                text: 'Yes'
                            }
                        ],
                        id: '2.7'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is at least one borrower currently vested on the title?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.9',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.9',
                                text: 'Yes'
                            }
                        ],
                        id: '2.8'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Has any borrower been on the title for at least 6 months?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2.10',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2.10',
                                text: 'Yes'
                            }
                        ],
                        id: '2.9'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the title currently vested in a trust or will it be at closing?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'No'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'Yes'
                            }
                        ],
                        id: '2.10'
                    }
                ],
                id: '2'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Title commitment questions are complete.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '3'
            }
        ]
    },
    {
        id: '17',
        name: 'Fraud Report',
        questions: [
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Is the fraud report on file?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '1',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '0'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Have all high and medium alerts been addressed with conditions or notes?',
                choices: [
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'Yes'
                    },
                    {
                        action: 'CONTINUE',
                        continueTo: '2',
                        text: 'No'
                    }
                ],
                id: '1'
            },
            {
                type: UnderwritingQuestionType.SINGLE_SELECT,
                variant: 'SIMPLE',
                text: 'Has the fraud report been checked for any undisclosed REO or other addresses?',
                choices: [
                    {
                        action: 'END',
                        text: 'Yes'
                    },
                    {
                        action: 'END',
                        text: 'No'
                    }
                ],
                id: '2'
            }
        ]
    },
    {
        id: '18',
        name: 'Parties Review',
        questions: [
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Have the following parties been validated on the fraud report?',
                next: {
                    action: 'CONTINUE',
                    continueTo: '1',
                    text: 'Continue'
                },
                id: '0'
            },
            {
                id: '1',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Appraiser</strong>',
                questions: [
                    {
                        id: '1.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.appraiser.name }}<br><small><span style="color: #888;">License #: {{ loan.appraiser.licenseNumber }}</span></small>',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '2',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '2',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Appraisal company</strong>',
                questions: [
                    {
                        id: '2.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.appraisalCompany.name }}',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '3',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Loan officer</strong>',
                questions: [
                    {
                        id: '3.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.loanOfficer.name }}<br><small><span style="color: #888;">NMLS ID: {{ loan.loanOfficer.nmlsId }}</span></small>',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '4',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Originating company</strong>',
                questions: [
                    {
                        id: '4.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.originatingCompany.name }}<br><small><span style="color: #888;">NMLS ID: {{ loan.originatingCompany.nmlsId }}</span></small>',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '5',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Realtors</strong>',
                questions: [
                    {
                        id: '5.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.buyersAgent.name }} (buyer\'s agent)',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '5.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.sellersAgent.name }} (seller\'s agent)',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '6',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '6',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '6',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Sellers</strong>',
                questions: [
                    {
                        id: '6.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.sellers.0.name }}',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '6.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '6.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '6.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.sellers.1.name }}',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '7',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '7',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                id: '7',
                type: UnderwritingQuestionType.GROUP,
                text: '<strong>Buyers</strong>',
                questions: [
                    {
                        id: '7.0',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.buyers.0.name }}',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '7.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '7.1',
                                text: 'No'
                            }
                        ]
                    },
                    {
                        id: '7.1',
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: '{{ loan.buyers.1.name }}',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '8',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '8',
                                text: 'No'
                            }
                        ]
                    }
                ]
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'End of parties review',
                next: {
                    action: 'END',
                    text: 'End'
                },
                id: '8'
            }
        ]
    },
    {
        id: '19',
        name: 'Flood Insurance',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the flood certificate on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the flood certificate valid for the life of the loan?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'DROPDOWN',
                        text: 'Which flood zone is listed on the certificate?\n',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AE'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A1-30'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AH'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AO'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'AR'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'A99'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'V'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'VE'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '3',
                                text: 'V1-30'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'X'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'B'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '4',
                                text: 'C'
                            }
                        ],
                        id: '0.2'
                    }
                ]
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Flood insurance is required.',
                next: {
                    action: 'CONTINUE',
                    continueTo: '5',
                    text: 'Continue'
                },
                id: '3'
            },
            {
                type: UnderwritingQuestionType.INFO,
                icon: UnderwritingIconType.INFO,
                text: 'Flood insurance is not required.',
                next: {
                    action: 'END',
                    text: 'Done'
                },
                id: '4'
            },
            {
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is flood insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.1',
                                text: 'No'
                            }
                        ],
                        id: '5.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.2',
                                text: 'No'
                            }
                        ],
                        id: '5.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '5.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '5.3',
                                text: 'No'
                            }
                        ],
                        id: '5.2'
                    },
                    {
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.flood_insurance_annual_premium',
                                label: 'What is the annual premium?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            },
                            {
                                dataSourceId: 'loan.data.flood_insurance_coverage_amount',
                                label: 'What is the coverage amount?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        },
                        id: '5.3'
                    }
                ],
                id: '5'
            }
        ]
    },
    {
        id: '20',
        name: 'Hazard Insurance',
        questions: [
            {
                id: '0',
                type: UnderwritingQuestionType.GROUP,
                questions: [
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is hazard insurance on file?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.1',
                                text: 'No'
                            }
                        ],
                        id: '0.0'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the mortgagee correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.2',
                                text: 'No'
                            }
                        ],
                        id: '0.1'
                    },
                    {
                        type: UnderwritingQuestionType.SINGLE_SELECT,
                        variant: 'SIMPLE',
                        text: 'Is the loan number correct?',
                        choices: [
                            {
                                action: 'CONTINUE',
                                continueTo: '0.3',
                                text: 'Yes'
                            },
                            {
                                action: 'CONTINUE',
                                continueTo: '0.3',
                                text: 'No'
                            }
                        ],
                        id: '0.2'
                    },
                    {
                        type: UnderwritingQuestionType.FORM,
                        fields: [
                            {
                                dataSourceId: 'loan.data.hazard_insurance_annual_premium',
                                label: 'What is the annual premium?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            },
                            {
                                dataSourceId: 'loan.data.hazard_insurance_coverage_amount',
                                label: 'What is the coverage amount?',
                                size: 6,
                                isDisabled: false,
                                type: CURRENCY
                            }
                        ],
                        next: {
                            action: 'END',
                            text: 'Done'
                        },
                        id: '0.3'
                    }
                ]
            }
        ]
    }
];
